.form-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 120px;
  }
  
  @media only screen and (max-width: 1500px) {
    .form-section {
      flex-wrap: wrap;
    }
  }
  
  .form-wrapper {
    width: 100%;
    max-width: 600px;
    margin-right: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input,
  select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%; /* make inputs and select fill their parent element */
  }
  
  button {
    padding: 10px;
    background-color: #0077c2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .image-wrapper {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    text-align: center;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  @media only screen and (max-width: 600px) {
    .form-wrapper, .image-wrapper {
      max-width: 100%; /* make form and image fill their parent element */
      margin-right: 0; /* remove right margin on form */
    }
  }
  